import { VueHeadMixin, createHead } from "@unhead/vue";
import { createApp } from "vue";
import VueGtag from "vue-gtag";

import * as Sentry from "@sentry/vue";

import "./utils/rAF";

import FacebookAnalytics from "./analytics/fb";

import LazyLoadWebP from "./directives/LazyLoadWebPNew";
import nprogress from "./plugins/nprogress";

import router from "./router";
import shop from "./shop";
import store from "./store";

import { version } from "../package.json";
import App from "./App.vue";

// register();

const app = createApp(App)
    .use(store)
    .use(VueGtag, {
        config: { id: "G-MGTQZVE4HY" },
        includes: [
            { id: "AW-986367431" },
        ],
    }, router)
    .use(FacebookAnalytics, {
        id: "289778884524362",
        router,
        debug: import.meta.env.DEV,
        ignoreRoutes: ["ThankYou"],
        profile: shop.getProfile,
    })
    .mixin(VueHeadMixin)
    .use(createHead())
    .use(LazyLoadWebP)
    .use(nprogress, {
        router: true,
        http: false,
    });

const sentry_dsn = "https://394b3067a50394f1f08c02804ae71951@sentry.cilory.com/5";
Sentry.init({
    app,
    dsn: (import.meta.env.MODE === "production" || import.meta.env.MODE === "qa") && sentry_dsn,
    release: version,
    environment: import.meta.env.MODE,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.01,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/www\.cilory\.com/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
});

app.use(router);
app.mount("#app");

window.addEventListener("mousewheel", () => {
    // do nothing
});

function updateOnlineStatus() {
    if (navigator.onLine) {
        document.body.classList.remove("ofl");
    } else {
        document.body.classList.add("ofl");
    }
    const condition = navigator.onLine ? "online" : "offline";
    if (window.M && window.M.toast) {
        window.M.toast({ html: `You are ${condition}` });
    }
}

window.addEventListener("online", updateOnlineStatus);
window.addEventListener("offline", updateOnlineStatus);

window.console = window.console || {
    log() {},
};

// For Collections - SortedMap
window.global = window;
