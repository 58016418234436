import config from './config'
import { warn, loadScript } from './utils'
import autoTracking from './tracking/autoTracking'

export default function (router, callback) {
    if (config.manual) {
        return
    }

    if (!config.id || !config.id.length) {
        warn('Please enter a Facebook Analaytics tracking ID');
        return
    }

    if (window.fbq)
        return;

    const domain = "https://connect.facebook.net";
    const resource = `${domain}/en_US/fbevents.js`;
    loadScript(resource, domain).then(() => {
        if (callback && typeof callback === 'function') {
            callback()
        }

        config.profile((user) => {
            if (user.email || user.mobile) {
                let firstname = user.firstname;
                let lastname = user.lastname;
                if (lastname.trim().length) {
                    lastname = lastname.trim();
                } else {
                    const sp = firstname.split(" ");
                    firstname = sp[0];
                    sp.splice(0, 1);
                    lastname = sp.join(" ");
                }
                fbq('init', config.id, {
                    fn: firstname.toLowerCase(),
                    ln: lastname ? lastname.toLowerCase() : null,
                    em: user.email,
                    ph: user.mobile ? '91' + user.mobile : null
                });
            } else {
                fbq('init', config.id);
            }
            fbq('track', 'PageView');
        });

        // fbq('init', config.id);
        // fbq('track', "PageView");

        autoTracking(router);
    }).catch(error => {
        warn("Ops! Something happened and fbevents.js couldn't be loaded", error);
        return error;
    });
}