import { createStore } from "vuex";

import shop from "../shop";
import cart from "./modules/cart";
import account from "./modules/account";
import product from "./modules/product";
import productlist from "./modules/product-list";
import behavior from "./modules/behavior";
// import zopimChatStore from "../components/chat/zopim/store";

const debug = import.meta.env.MODE === "production";

const recentSearchesFromStrorage = JSON.parse((typeof (Storage) !== "undefined" && localStorage.recentSearches) || "[]");
const chatWidgetVisibleFromStorage = typeof (Storage) !== "undefined" && localStorage.chatWidgetVisible === "true";

const globalState = {
    chatWidgetVisible: chatWidgetVisibleFromStorage,
    homePageData: {},
    categories: [],
    showLoader: false,
    recentSearches: recentSearchesFromStrorage,
    configs: {},
};

const getters = {
    chatWidgetVisible: (state) => state.chatWidgetVisible,
    homePageData: (state) => state.homePageData,
    categories: (state) => state.categories,
    showLoader: (state) => state.showLoader,
    recentSearches: (state) => state.recentSearches,
    configs: (state) => state.configs,
};

const mutations = {
    chatWidgetVisible(state, chatWidgetVisible) {
        state.chatWidgetVisible = chatWidgetVisible;
    },
    homePageData(state, data) {
        state.homePageData = data;
    },

    categories(state, data) {
        state.categories = data;
    },

    showLoader(state, data) {
        state.showLoader = data;
    },

    recentlySearched(state, searchStr) {
        if (!state.recentSearches.includes(searchStr)) {
            if (state.recentSearches.length >= 20) {
                state.recentSearches.splice(state.recentSearches.length - 1, 1);
            }

            state.recentSearches.splice(0, 0, searchStr);
            if (typeof (Storage) !== "undefined") {
                try {
                    localStorage.setItem("recentSearches", JSON.stringify(state.recentSearches));
                } catch (e) {
                    //
                }
            }
        }
    },

    removeSearchItem(state, searchStr) {
        const index = state.recentSearches.indexOf(searchStr);
        state.recentSearches.splice(index, 1);
        if (typeof (Storage) !== "undefined") {
            try {
                localStorage.setItem("recentSearches", JSON.stringify(state.recentSearches));
            } catch (e) {
                //
            }
        }
    },
    configs(state, data) {
        state.configs = data;
    },
};

const actions = {
    chatWidgetVisible({ commit }, chatWidgetVisibleData) {
        commit("chatWidgetVisible", chatWidgetVisibleData);
        if (typeof (Storage) !== "undefined") {
            try {
                localStorage.setItem("chatWidgetVisible", chatWidgetVisibleData);
            } catch (e) {
                //
            }
        }
    },
    homePageData({ commit }) {
        shop.getHomePageData((response) => {
            commit("homePageData", response);
        }, () => {
            // do nothing
        });
    },

    categories({ commit }) {
        shop.getCategories((response) => {
            commit("categories", response);
        });
    },

    showLoader({ commit }, data) {
        commit("showLoader", data);
    },

    recentlySearched({ commit }, searchStr) {
        commit("recentlySearched", searchStr);
    },

    removeSearchItem({ commit }, searchItem) {
        commit("removeSearchItem", searchItem);
    },
    configs({ commit }) {
        shop.getConfigs("COD_FEE|PS_SHIPPING_FEE|PS_SHIPPING_FREE_PRICE|PS_ORDER_RETURN_NB_DAYS|PS_GIFT_WRAPPING_PRICE|SITE_SHARE_MSG|WHATSAPP_CHAT|WHATSAPP_CHAT_CONTACT|WHATSAPP_PHONE|PS_SHOP_PHONE|PS_SHOP_EMAIL|PERSONALIZE_WIDGET_DISPLAY_DAYS", (response) => {
            commit("configs", response);
        });
    },
};

export default createStore({
    state: globalState,
    getters,
    actions,
    mutations,
    modules: {
        cart,
        account,
        product,
        productlist,
        behavior,
    },
    strict: debug,
});
