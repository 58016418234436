import { createRouter, createWebHistory } from "vue-router";
import { captureException } from "@sentry/vue";

import shop from "./shop";
import store from "./store";

// console.log("user already logged in: " + localStorage.loggedIn);
if (typeof (Storage) !== "undefined") {
    if (localStorage.loggedIn && localStorage.loggedIn === "true") {
        store.dispatch("loggedIn");
    }
    if (localStorage.loggedIn && localStorage.loggedIn === "false") {
        store.dispatch("loggedOut");
    }
}

const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        return savedPosition;
    }
    const position = {};
    // new navigation.
    // scroll to anchor by returning the selector
    if (to.hash) {
        // position.selector = to.hash
    }
    // check if any matched route config has meta that requires scrolling to top
    if (to.matched.some((m) => m.meta.scrollToTop)) {
        // cords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.left = 0;
        position.top = 0;
    }
    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    return position;
};

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior,
    routes: [
        {
            path: "",
            component: () => import("./components/Home.vue"),
            name: "Home",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/men",
            component: () => import("./components/Home.vue"),
            name: "Men",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/women",
            component: () => import("./components/Home.vue"),
            name: "Women",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/kids",
            redirect: "/kids-store",
        },
        {
            path: "/kids-store",
            component: () => import("./components/Home.vue"),
            name: "Kids",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/my-account",
            component: () => import("./components/MyAccount.vue"),
            name: "MyAccount",
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/identity",
            component: () => import("./components/Identity.vue"),
            name: "Identity",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/authentication",
            component: () => import("./components/Login2.vue"),
            name: "Login",
            meta: {
                scrollToTop: true,
            },
            beforeEnter: (to, from, next) => {
                if (store.getters.loggedIn) {
                    if (to.query.back) {
                        next({ path: to.query.back });
                    } else {
                        next({ path: "/" });
                    }
                } else {
                    next();
                }
            },
        },
        {
            path: "/authentication/signup",
            component: () => import("./components/Signup.vue"),
            name: "Signup",
            meta: {
                scrollToTop: true,
            },
            beforeEnter: (to, from, next) => {
                if (store.getters.loggedIn) {
                    if (to.query.back) {
                        next({ path: to.query.back });
                    } else {
                        next({ path: "/" });
                    }
                } else {
                    next();
                }
            },
        },
        {
            path: "/authentication/signup/otp",
            component: () => import("./components/SignupOTP.vue"),
            name: "SignupOTP",
            meta: {
                scrollToTop: true,
            },
            beforeEnter: (to, from, next) => {
                if (store.getters.loggedIn) {
                    if (to.query.back) {
                        next({ path: to.query.back });
                    } else {
                        next({ path: "/" });
                    }
                } else {
                    next();
                }
            },
        },
        {
            path: "/authentication/signup/details",
            component: () => import("./components/SignupDetails.vue"),
            name: "SignupDetails",
            meta: {
                scrollToTop: true,
            },
            beforeEnter: (to, from, next) => {
                if (store.getters.loggedIn) {
                    if (to.query.back) {
                        next({ path: to.query.back });
                    } else {
                        next({ path: "/" });
                    }
                } else {
                    next();
                }
            },
        },
        {
            path: "/forgot-your-password",
            component: () => import("./components/ForgotPassword.vue"),
            name: "ForgotPassword",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/forgot-otp-verify",
            component: () => import("./components/ForgotOTP.vue"),
            name: "ForgotOTP",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/reset-password",
            component: () => import("./components/ResetPasswordOTP.vue"),
            name: "ResetPasswordOTP",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/addresses",
            component: () => import("./components/Addresses.vue"),
            name: "Addresses",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/address",
            component: () => import("./components/EditAddress.vue"),
            name: "AddAddress",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/addresses/:id_address",
            component: () => import("./components/EditAddress.vue"),
            name: "EditAddress",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/wallet",
            component: () => import("./components/Wallet.vue"),
            name: "Wallet",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/account",
            component: () => import("./components/EditWalletAccount.vue"),
            name: "AddWalletAccount",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/accounts/:id_account",
            component: () => import("./components/EditWalletAccount.vue"),
            name: "EditWalletAccount",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/wishlist",
            component: () => import("./components/Wishlist.vue"),
            name: "Wishlist",
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/order-history",
            component: () => import("./components/OrderHistory.vue"),
            name: "OrderHistory",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/order-history/:id_order",
            component: () => import("./components/OrderDetails.vue"),
            name: "OrderDetails",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/order-reviews",
            component: () => import("./components/OrderReviews.vue"),
            name: "OrderReviews",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/search",
            component: () => import("./components/SearchProductList.vue"),
            name: "Search",
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/:category_link_rewrite?/:id_product(\\d+)-:product_link_rewrite.html",
            component: () => import("./components/ProductDetails.vue"),
            name: "ProductDetails",
            meta: {
                requiresAuth: false,
                scrollToTop: true,
            },
        },
        {
            path: "/:id_category(\\d+)-:category_link_rewrite/:filterNameValue?",
            component: () => import("./components/ProductList.vue"),
            name: "ProductList",
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/:category_link_rewrite?/:id_product(\\d+)-:product_link_rewrite/reviews",
            component: () => import("./components/ProductReviews.vue"),
            name: "ProductReviews",
            meta: {
                requiresAuth: false,
                scrollToTop: true,
            },
        },
        {
            path: "/:category_link_rewrite?/:id_product(\\d+)-:product_link_rewrite/write-review",
            component: () => import("./components/WriteReview.vue"),
            name: "WriteReview",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/:id_manufacturer(\\d+)_:brand_link_rewrite",
            component: () => import("./components/BrandProductList.vue"),
            name: "BrandProductList",
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/size-chart/:id_product",
            component: () => import("./components/SizeChart.vue"),
            name: "SizeChart",
            meta: {
                requiresAuth: false,
                scrollToTop: true,
            },
        },
        {
            path: "/quick-order",
            component: () => import("./components/MyBag.vue"),
            name: "MyBag",
            meta: {
                requiresAuth: false,
                scrollToTop: true,
            },
        },
        {
            path: "/quick-order/:_id_cart",
            redirect: "/quick-order",
        },
        {
            path: "/select-delivery-address",
            component: () => import("./components/SelectDeliveryAddress.vue"),
            name: "SelectDeliveryAddress",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/select-payment",
            component: () => import("./components/SelectPayment.vue"),
            name: "SelectPayment",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/thank-you",
            component: () => import("./components/ThankYou.vue"),
            name: "ThankYou",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/payment-failed",
            component: () => import("./components/PaymentFailed.vue"),
            name: "PaymentFailed",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/contact-us",
            component: () => import("./components/ContactUs.vue"),
            name: "ContactUs",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/content/11-faq",
            component: () => import("./components/FAQ.vue"),
            name: "FAQ",
            meta: {
                scrollToTop: true,
            },
        },
        // {
        //     path: "/content/6-Cancellation-Returns",
        //     component: () => import("./components/Cancellation.vue"),
        //     name: "Cancellation",
        //     meta: {
        //         scrollToTop: true,
        //     },
        // },
        // {
        //     path: "/content/1-Delivery",
        //     component: () => import("./components/Delivery.vue"),
        //     name: "Delivery",
        //     meta: {
        //         scrollToTop: true,
        //     },
        // },
        {
            path: "/content/16-men",
            redirect: "/men",
        },
        {
            path: "/content/15-women",
            redirect: "/women",
        },
        {
            path: "/content/:id_cms(\\d+)-:cms_link_rewrite",
            component: () => import("./components/Cms.vue"),
            name: "Cms",
            meta: {
                requiresAuth: false,
                scrollToTop: true,
            },
        },
        {
            path: "/referral-program/tnc",
            component: () => import("./components/ReferAndEarnTnC.vue"),
            name: "ReferAndEarnTnC",
            meta: {
                requiresAuth: false,
                scrollToTop: true,
            },
        },
        {
            path: "/landing/:id_landing_page(\\d+)-:landing_page_rewrite",
            component: () => import("./components/LandingPage.vue"),
            name: "LandingPage",
            meta: {
                requiresAuth: false,
                scrollToTop: true,
            },
        },
        {
            path: "/:catchAll(.*)",
            component: () => import("./components/PageNotFound.vue"),
            name: "PageNotFound",
            meta: {
                scrollToTop: true,
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // get user state from backend
        shop.getUserState((response) => {
            if (response.user) {
                store.dispatch("loggedIn");
            } else {
                store.dispatch("loggedOut");
            }

            if (!store.getters.loggedIn) {
                next({
                    path: "/authentication",
                    query: { back: to.fullPath },
                });
            } else {
                next();
            }
        });
        /*
        if (typeof (Storage) !== "undefined" && typeof localStorage.loggedIn === "undefined") {
            // get user state from backend
            shop.getUserState((response) => {
                if (response.user) {
                    store.dispatch("loggedIn");
                } else {
                    store.dispatch("loggedOut");
                }

                if (!store.getters.loggedIn) {
                    next({
                        path: "/authentication",
                        query: { back: to.fullPath },
                    });
                } else {
                    next();
                }
            });
        } else if (!store.getters.loggedIn) {
            next({
                path: "/authentication",
                query: { back: to.fullPath },
            });
        } else {
            next();
        }
        */
    } else {
        if (to.name === "PageNotFound") {
            captureException(new Error("PageNotFound"), {
                extra: {
                    params: JSON.stringify(from.params),
                    path: from.path,
                    query: from.query,
                    hash: from.hash,
                    location: window.location.href,
                    to: JSON.stringify(to),
                },
            });
        }
        next();
    }
});

export default router;
