<template>
    <div id="app">
        <Layout />
    <!-- <router-view></router-view> -->
    </div>
</template>

<script>
import Layout from "./components/Layout.vue";

export default {
    name: "app",
    head() {
        return {
            title: "Online Shopping T-Shirt, Kurtis, Suits, Lingerie, Dress, Clubwear, Fashion Jewellery, Nightwear - Cilory",
            meta: [
                { name: "description", content: "Cilory is India’s first online premium personal shop for buying Lingerie, Clubwear, Kurtis, Suits, T-Shirts, Dress and Handbags", vmid: "desc" },
                { name: "keywords", content: "Buy Clubwear Online, Online Dress Shopping, Buy Nightwear Online,Buy Superman T-Shirt,Buy Kurtis Online,Buy T-Shirts,Buy Suits,Buy Lingerie,Superman,Batman", vmid: "keywords" },
                { property: "og:description", content: "Cilory is India’s first online premium personal shop for buying Lingerie, Clubwear, Kurtis, Suits, T-Shirts, Dress and Handbags", vmid: "ogdesc" },
                { property: "og:title", content: "Online Shopping T-Shirt, Kurtis, Suits, Lingerie, Dress, Clubwear, Fashion Jewellery, Nightwear - Cilory", vmid: "ogtitle" },
            ],
        };
    },
    created() {
        console.log("App created");
        import("./materialize").then(() => {
            // console.log("materialize-css/js loaded");
        });
        this.setReferrerCookies();
    },
    methods: {
        setReferrerCookies() {
            const ref1 = this.getCookie("ref1");
            const referrer = window.location.href;
            if (window.location.href.indexOf("utm_") >= 0 || window.location.href.indexOf("gclid") >= 0 || window.location.href.indexOf("wbraid") >= 0
              || window.location.href.indexOf("gbraid") >= 0 || window.location.href.indexOf("FacebookAds") >= 0
              || window.location.href.indexOf("fbclid") >= 0 || window.location.href.indexOf("facebook.com") >= 0) {
                if (!ref1) {
                    this.setCookie("ref1", referrer, 180);
                } else {
                    this.setCookie("ref2", referrer, 180);
                }
            }
        },

        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            const expires = `expires=${d.toUTCString()}`;
            document.cookie = `${cname}=${cvalue}; ${expires}; path=/; SameSite=None; Secure`;
        },
        getCookie(cname) {
            const name = `${cname}=`;
            const ca = document.cookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === " ") c = c.substring(1);
                if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
            }
            return "";
        },
        /*
        getParameterByName(name, url) {
            let urlToUse = url;
            if (!urlToUse) {
                urlToUse = window.location.href;
            }
            const paramName = name.replace(/[[\]]/g, "\\$&");
            const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);
            const results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
        */
    },
    components: {
        Layout,
    },
};
</script>

<style lang="scss">

body.ofl, .grayscale {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

// Color
@import "materialize-css/sass/components/color-variables";
@import "materialize-css/sass/components/color-classes";

// Variables;
@import "materialize-css/sass/components/variables";

// Reset
@import "materialize-css/sass/components/normalize";

// components
@import "materialize-css/sass/components/global";
@import "materialize-css/sass/components/badges";
// @import "materialize-css/sass/components/icons-material-design";
@import "materialize-css/sass/components/grid";
.col .row {
  margin-left: auto;
  margin-right: auto;
}
@import "materialize-css/sass/components/navbar";
nav {
  height: 52px;
  line-height: 52px;
  // background: #232f3e;
  background: #FFF;
}
.navbar-fixed {
  height: 52px;
  z-index: 996;
}
nav a {
    height: 52px;
    line-height: 52px;
    padding: 0 12px;
    display: inline-block;
}
nav .brand-logo {
    left: unset;
    transform: none;
}
.brand-logo img {
  top: 6px;
  position: relative;
}
ul.right li a {
    padding: 0 9px;
}
.badge-cart {
  background: #fcbb6a;
  border-radius: 9px;
  font-size: 10px;
  font-weight: 500;
  height: 15px;
  line-height: 16px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 12px;
  width: 15px;
  z-index: 1;
  color: #000;
}
ul.breadcrumb-list {
    display: inline-block;
}
ul.breadcrumb-list li {
    display: inline-block;
    margin-right: 5px;
}
ul.breadcrumb-list li::after {
    content: '/';
    margin-left: 5px;
    color: #282C3F;
}
ul.breadcrumb-list li:first-child {
    font-weight: 500;
}
ul.breadcrumb-list li:last-child::after {
    content: '';
}
@import "materialize-css/sass/components/typography";

@import "materialize-css/sass/components/cards";
@import "materialize-css/sass/components/toast";
#toast-container {
  bottom: 9%;
}

.toast {
  word-break: normal;
  margin: 0 auto;
  margin-bottom: 4px;
  width: 96%;
  border-radius: 4px;
  font-size: 1rem;
  padding: 10px 12px;
  min-height: 44px;
  // background-color: #fff;
  // color: #000;
  // border: 1px solid #444;
}

.toast .toast-action {
  height: unset;
  line-height: unset;
  margin-right: -16px
}

@import "materialize-css/sass/components/tabs";
.tabs {
  // background: #232f3e;
  background: #FFF;
}

.tabs .tab a, .tabs .tab a.active, .tabs .tab a:hover {
  color: #000;
  background: transparent;
}

.tabs .tab a.active {
  border-bottom: 2px solid #fcbb6a;
}

.tabs .indicator {
  background-color: #fcbb6a;
}

@import "materialize-css/sass/components/buttons";
.btn, .btn-flat {
    height: 42px;
    line-height: 42px;
}

.btn-small {
    height: 32px;
    line-height: 32px;
}

.preloader-wrapper.btn-loader {
  width: 20px !important;
  height: 20px !important;
  top: 6px;
  margin-left: 12px;
}
.preloader-wrapper.btn-loader .spinner-layer {
  border-color: inherit;
}
.preloader-wrapper.btn-loader .circle {
  border-width: 2px;
}

// check for modal
@import "materialize-css/sass/components/modal";

@import "materialize-css/sass/components/waves";

@import "materialize-css/sass/components/forms/forms";

input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

textarea.materialize-textarea {
  height: unset;
  padding: 8px;
}

.input-field.col label {
    left: 1.3rem;
    top: -2px
}
.input-field > label:not(.label-icon).active {
    -webkit-transform: translateY(-16px) scale(0.8);
    transform: translateY(-16px) scale(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    left: 0.8rem;
}

@import "materialize-css/sass/components/sidenav";
.sidenav {
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}
#nav-items {
  transition: all .3s;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -o-transition: all .3s;
  width: 280px;
  box-shadow: none;
}
#nav-items.active {
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.sidenav-overlay {
  z-index: 996;
  background-color: rgba(0, 0, 0, 0.75);
}
.sidenav .subheader {
  font-size: 12px;
}

.sidenav li > a {
  padding: 0 22px;
  font-weight: 400;
}

.sidenav li > a.category {
  border-bottom: 1px solid #efefef;
  font-weight: 500;
}

.sidenav li > a.category.level2, .sidenav li > a.category.level3 {
  font-weight: 300;
}
.sidenav li > a.category.level3 {
  padding: 0 32px;
}
.sidenav li > a.category.level2.expand {
  font-weight: 500;
}

.sidenav li > a.category > i {
  margin: 0;
}
.sidenav li > a.category.level2 > i {
  font-size: 16px;
  text-align: center;
}

.sidenav li.nav-home > a > i, .nav-home a {
  color: #fff !important;
  line-height: 64px !important;
  height: 64px !important;
}

.sidenav li a i {
  margin-right: 10px;
  height: 48px;
  line-height: 48px;
}

.sidenav li a svg {
  margin-right: 10px;
  line-height: 48px;
  vertical-align: middle;
}

@import "materialize-css/sass/components/preloader";

.noScroll {
  overflow: hidden;
}

.cgray {
  background: #eee;
}

.no-margin {
  margin: 0
}

.no-padding {
  padding: 0
}

.no-margin-top {
  margin-top: 0
}

.no-margin-bottom {
  margin-bottom: 0
}
.valign-wrapper {
  z-index: 1;
}

select {
  border: 1px solid #CCC;
  outline: none;
}
select:focus {
    outline: none;
}

.btn-100 {
  width: 100%;
  height: 42px;
  line-height: 42px;
}

.btn-small {
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
}
ul.errors {
  background: #b9151b;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 2px;
}

ul.errors li {
  list-style: none
}
img.lazy {
    opacity: 0;
    // -webkit-transition: opacity .3s linear;
    // transition: opacity .3s linear;
}

img.lazy.loaded {
  opacity: 1;
}

.placeholder-item {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 2px;
  margin: 0 auto;
  padding: 5px
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.animated-background {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eeeeee;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  position: relative;
}

.outlined .background-masker {
  border: 1px solid #ddd;
}

.outlined:hover .background-masker {
  border: none;
}

.outlined:hover .background-masker:hover {
  border: 1px solid #ccc;
  z-index: 1;
}

.background-masker {
  background: #fff;
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.btn-g-orange, .btn-g-orange-light, .btn-g-gray {
  width: 100%;
  height: 48px;
  border-style: solid;
  border-width: .1rem;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 0.1rem 0 rgba(255,255,255,.6) inset;
  color: #000;
}
.btn-g-orange.disabled, .btn-g-orange-light.disabled, .btn-g-gray.disabled, .btn-g-orange[disabled] {
  background: #dfdfdf;
  border-color: #9f9f9f;
  color: #9f9f9f;
  pointer-events: none;
}
.btn-g-orange {
  background: #f2ad58;
  background: -webkit-linear-gradient(top,#f6c88f,#ed9220);
  background: linear-gradient(to bottom,#f6c88f,#ed9220);
  border-color: #ca7c1b #be751a #a56616;
}
.btn-g-orange-light {
  border-color: #a88734 #9c7e31 #846a29;
  background: #f4d078;
  background: -webkit-linear-gradient(top,#f7dfa5,#f0c14b);
  background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
}
.btn-g-gray {
  background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
  border-color: #ADB1B8 #A2A6AC #8D9096;
}

.btn-g-orange-new, .btn-g-orange-new:focus {
  background-color: #ffd814;
  border-color: #ffd814;
  color: #000;

  width: 100%;
  height: 48px;
  border-style: solid;
  border-width: .1rem;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  text-decoration: none;
  vertical-align: middle;
  color: #000;
}

.btn-g-orange-new:focus {
  background-color: #f0b800;
  border-color: #f0b800;
  border: 1px solid burlywood;
}

.search-country, .search-country:focus {
    border: none !important;
    box-shadow: none !important;
    font-size: 1rem;
    margin-bottom: 0 !important;
    width: -webkit-fill-available;
    display: flex;
    color: #888;
}
.countryModal .modal-container {
    width: 88%;
}
.countryModal .modal-body {
    padding: 0;
    min-height: 400px;
}
.countryModal .modal-footer {
    height: 46px;
}

</style>
