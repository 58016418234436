import { setUser } from "@sentry/vue";
import shop from "../../shop";

const accountState = {
    loggedIn: false,

    user: {
        name: "",
        email: "",
        gender: -1,
        mobile: "",
        country_code: "",
    },

    recentOrder: null,

    wallet: {
        balance: 0,
        accounts: [],
        transactions: [],
    },

    orders: [],
    ordersPageSize: 10,
    fetchingOrders: false,
    noMoreOrders: false,
    noOrdersFound: false,
    firstLoadOrders: false,

    addresses: [],
    noAddressFound: false,
    fetchingAddresses: false,
};

const getters = {
    loggedIn: (state) => state.loggedIn,
    user: (state) => state.user,

    wallet: (state) => state.wallet,
    accountById: (state) => (id) => state.wallet.accounts.find((account) => account.id_account === id),

    recentOrder: (state) => state.recentOrder,
    orders: (state) => state.orders,
    noOrdersFound: (state) => state.noOrdersFound,
    noMoreOrders: (state) => state.noMoreOrders,
    fetchingOrders: (state) => state.fetchingOrders,
    firstLoadOrders: (state) => state.firstLoadOrders,

    addresses: (state) => state.addresses,
    addressById: (state) => (id) => state.addresses.find((address) => address.id_address === id),
    noAddressFound: (state) => state.noAddressFound,
    fetchingAddresses: (state) => state.fetchingAddresses,
};

const actions = {
    loggedIn({ commit }) {
        commit("loggedIn", true);
        if (typeof (Storage) !== "undefined") {
            try {
                localStorage.setItem("loggedIn", true);
            } catch (e) {
                localStorage.removeItem("recentlyViewedProducts");
                localStorage.removeItem("recentSearches");

                localStorage.setItem("loggedIn", true);
            }
        }
    },

    loggedOut({ commit, dispatch }) {
        commit("loggedIn", false);
        if (typeof (Storage) !== "undefined") {
            try {
                localStorage.setItem("loggedIn", false);
            } catch (e) {
                //
            }
        }

        commit("user", { name: "", email: "", gender: -1, mobile: "", country_code: "" });
        commit("recentOrder", null);
        commit("orders", { orders: [], pageNum: 1 });
        commit("wallet", { balance: 0, accounts: [], transactions: [] });
        commit("walletBalance", 0);
        commit("addresses", []);

        dispatch("removeAllWishlistProductsFromLS", { root: true });
    },

    getProfile({ commit }) {
        shop.getProfile((response) => {
            const user = {};
            user.name = response.firstname + (response.lastname ? response.lastname : "");
            user.email = response.email;
            user.gender = response.gender;
            user.mobile = response.mobile;
            user.country_code = response.country_code;
            setUser(user);
            commit("user", user);
        });
    },

    getRecentOrder({ commit }) {
        shop.getOrders({}, (response) => {
            if (response.orders.length) {
                commit("orders", { orders: response.orders, pageNum: 1 });
                commit("recentOrder", response.orders[0]);
            } else {
                commit("recentOrder", null);
            }
        });
    },

    clearOrders({ commit }) {
        commit("orders", { orders: [], pageNum: 1 });
    },

    getOrders({ commit, state }) {
        commit("fetchingOrders", true);
        const start = state.orders.length + 1;
        const pageNum = start === 1 ? 1 : parseInt((start / state.ordersPageSize) + 1);
        commit("firstLoadOrders", pageNum === 1);
        shop.getOrders({ p: pageNum }, (response) => {
            if (response.orders.length) {
                commit("orders", { orders: response.orders, pageNum });
            } else {
                commit("orders", { orders: [], pageNum: 1 });
            }
            commit("fetchingOrders", false);
            commit("firstLoadOrders", false);
        }, () => {
            commit("fetchingOrders", false);
            commit("firstLoadOrders", false);
        });
    },

    getWalletBalance({ commit }) {
        shop.getWallet((response) => {
            commit("walletBalance", response.balance);
        });
    },

    getWalletDetails({ commit }) {
        shop.getWalletDetails((response) => {
            commit("wallet", response);
        });
    },

    getAllAddresses({ commit }) {
        commit("fetchingAddresses", true);
        shop.getAddresses({}, (response) => {
            if (response.addresses.length) {
                commit("addresses", response.addresses);
                commit("fetchingAddresses", false);
            } else {
                commit("addresses", []);
                commit("fetchingAddresses", false);
            }
        });
    },

};

const mutations = {
    loggedIn(state, loggedIn) {
        state.loggedIn = loggedIn;
    },

    user(state, user) {
        state.user = user;
    },

    walletBalance(state, walletBalance) {
        state.wallet.balance = walletBalance;
    },

    wallet(state, wallet) {
        state.wallet = wallet;
    },

    recentOrder(state, recentOrder) {
        state.recentOrder = recentOrder;
    },

    fetchingOrders(state, isFetching) {
        state.fetchingOrders = isFetching;
    },

    firstLoadOrders(state, isfirstLoadOrders) {
        state.firstLoadOrders = isfirstLoadOrders;
    },

    orders(state, data) {
        state.noOrdersFound = data.orders.length === 0;
        state.noMoreOrders = data.orders.length < state.ordersPageSize;

        if (data.pageNum === 1) {
            state.orders = data.orders;
        } else {
            state.orders = state.orders.concat(data.orders);
        }
    },

    addresses(state, addresses) {
        state.addresses = addresses;
        state.noAddressFound = addresses.length === 0;
    },

    fetchingAddresses(state, isFetching) {
        state.fetchingAddresses = isFetching;
    },
};

export default {
    state: accountState,
    getters,
    actions,
    mutations,
};
